import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CardService } from '../src/services/card.service';
import { ActivatedRoute } from '@angular/router';
import { Card } from '../src/model/card';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Location } from '@angular/common';
import { DeckService } from '../src/services/deck.service';
import { Deck } from '../src/model/deck';
import { TranslateService } from '../src/services/translate.service';

@Component({
  selector: 'app-memorize',
  templateUrl: './memorize.component.html',
  styleUrls: ['./memorize.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // state('*', style({ 'overflow-y': 'hidden' })),
      // state('void', style({ 'overflow-y': 'hidden' })),
      transition(
        ':enter', [
        style({ opacity: 0 }), // transform: 'translateX(100%)'
        animate('600ms', style({ 'opacity': 1 })) // transform: 'translateX(0)',
      ]
      ),
      transition(
        ':leave', [
        style({ opacity: 1 }), // transform: 'translateX(0)',
        animate('600ms', style({ 'opacity': 0 })) // transform: 'translateX(100%)',
      ]
      )
    ])
  ]
})
export class MemorizeComponent implements OnInit {
  deckId: string;
  allCards: Card[] = [];
  wrongCards: Card[] = [];
  currentCards: Card[] = [];
  card: Card;
  deck: Deck;
  isFlipped = false;
  progress = 0;
  haveCardsBeenRetrieved = false;

  constructor(private route: ActivatedRoute,
    private cardService: CardService,
    private deckService: DeckService,
    private location: Location,
    private translate: TranslateService) { }

  ngOnInit() {
    this.deckId = this.route.snapshot.paramMap.get('id');
    this.cardService.getCardsForDeckId(this.deckId)
      .subscribe(card => {
        this.addCardToSimulation(card);
        this.haveCardsBeenRetrieved = true;
      });

    this.deckService.getDeckById(this.deckId).subscribe(deck => this.deck = deck);
  }

  onCorrectCard(): void {
    this.goToNextCard();
    this.isFlipped = false;
  }

  onIncorrectCard(): void {
    this.wrongCards.push(this.currentCards[0]);
    this.goToNextCard();
    this.isFlipped = false;
  }

  addCardToSimulation(card: Card): void {
    this.allCards.push(card);
    this.currentCards.push(card);

    if (!this.card) {
      this.card = this.currentCards[0];
    }
  }

  goToNextCard(): void {
    this.currentCards.shift();
    this.card = this.currentCards[0];
    this.calculateProgress();
  }

  calculateProgress(): void {
    this.progress = this.getPercent(this.allCards.length - this.currentCards.length, this.allCards.length);
  }

  resetSimulation(): void {
    this.currentCards = Object.assign([], this.allCards);
    this.wrongCards = [];
    this.card = this.currentCards[0];
    this.calculateProgress();
  }

  resetWrongCardSimulation(): void {
    this.currentCards = Object.assign([], this.wrongCards);
    this.card = this.currentCards[0];
    this.wrongCards = [];
    this.calculateProgress();
  }

  goBack(): void {
    this.location.back();
  }

  isResetVisible(): boolean {
    if (!this.haveCardsBeenRetrieved) {
      return false;
    }
    return this.currentCards.length === 0;
  }

  getIsFlipped(): boolean {
    this.isFlipped = !this.isFlipped;
    return this.isFlipped;
  }

  getPercent(percentFor, percentOf): number {
    return Math.floor(percentFor / percentOf * 100);
  }

  getNumberOfCorrectCards(): number {
    return this.allCards.length - this.wrongCards.length;
  }

  getNumberOfIncorrectCards(): number {
    return this.wrongCards.length;
  }
}
