import { Card } from './card';

export class Deck {
    id = '';
    name = '';
    uid = '';
    creationDate: Date;
    frontLanguage = '';
    backLanguage = '';
    image = '';
    description = '';
}
