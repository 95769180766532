import { mergeMap } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { DeckService } from '../src/services/deck.service';
import { Deck } from '../src/model/deck';
import { AuthService } from '../src/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import 'rxjs/add/operator/map';
import { CardService } from '../src/services/card.service';
import { from } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-decks',
  templateUrl: './decks.component.html',
  styleUrls: ['./decks.component.scss']
})
export class DecksComponent implements OnInit {
  decks: Deck[] = [];

  constructor(private deckService: DeckService,
    private authService: AuthService,
    public createDialogRef: MatDialog,
    public deleteDialogRef: MatDialog,
    private location: Location) {
  }

  ngOnInit() {
    this.deckService.getDecksByUserId(this.authService.getUserId())
      .subscribe(decks => this.decks = decks);
  }

  onDelete(deck: Deck): void {
    const dialogRef = this.deleteDialogRef.open(DeleteDeckDialogComponent, {
      data: deck
    });

    dialogRef.afterClosed().subscribe();
  }

  goBack(): void {
    this.location.back();
  }
}
@Component({
  selector: 'app-delete-deck-dialog',
  templateUrl: 'delete-deck-dialog.html',
})
export class DeleteDeckDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteDeckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public deck: Deck,
    private deckService: DeckService,
    private cardService: CardService) {
  }

  onOkDeleteClick(): void {

    this.deckService.deleteDeck(this.deck)
      .map(deletedDeck => deletedDeck.id)
      .pipe(mergeMap(deckId => this.cardService.getCardsForDeckId(deckId)))
      .pipe(cards => from(cards))
      .pipe(mergeMap(card => this.cardService.deleteCard(card)))
      .subscribe();
    this.dialogRef.close();
  }

  onCancelDeleteClick(): void {
    this.dialogRef.close();
  }
}

