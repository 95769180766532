import { Injectable, OnInit, Query } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { UserSettings } from '../model/userSettings';

const settings = { timestampsInSnapshots: true };
// TODO settings should be retrieved from environment
export const config = {
    apiKey: 'AIzaSyDC065QP86xTWvVvHswCokziUMuC2BZ3-w',
    authDomain: 'flashdict-a85d1.firebaseapp.com',
    databaseURL: 'https://flashdict-a85d1.firebaseio.com',
    projectId: 'flashdict-a85d1',
    storageBucket: 'flashdict-a85d1.appspot.com',
    messagingSenderId: '797151095732'
};

@Injectable()
export class SettingsService {

    getUserSettingsByUserId(userId: string): Observable<UserSettings> {
        const ref = firebase.firestore().collection('settings')
            .where('uid', '==', userId);

        return new Observable((observer) => {
            ref.onSnapshot((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    const settings = new UserSettings();
                    Object.assign(settings, doc.data());
                    settings.id = doc.id;
                    observer.next(settings);
                });
            });
        });
    }

    addSettings(settings: UserSettings): Observable<UserSettings> {

        const ref = firebase.firestore().collection('settings');

        return new Observable((observer) => {
            ref.add(Object.assign({}, settings)).then((doc) => {
                observer.next(settings);
            });
        });
    }

    updateSettings(settings: UserSettings): Observable<Boolean> {
        // console.log(settings);
        const ref = firebase.firestore().collection('settings');
        return new Observable((observer) => {
            ref.doc(settings.id).set(Object.assign({}, settings)).then(() => {
                observer.next(true);
            });
        });
    }

    onSettingsChanged(userId: string): Observable<UserSettings> {
        const ref = firebase.firestore().collection('settings')
            .where('uid', '==', userId);

        return new Observable((observer) => {
            ref.onSnapshot(doc => {
                const allDocs = doc.docs;
                allDocs.forEach(function (thisdoc) {
                    const settings = new UserSettings();
                    Object.assign(settings, thisdoc.data());
                    settings.id = thisdoc.id;
                    observer.next(settings);
                });
            });
        });
    }
}


