import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/src/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  hide = true;

  constructor(private authService: AuthService) {
  }

  register(userEmail: string, userPassword: string): void {
    this.authService.registerByUserEmail(userEmail, userPassword);
  }
}
