import { Injectable, OnInit, Query } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { Deck } from '../model/deck';

const settings = { timestampsInSnapshots: true };
// TODO settings should be retrieved from environment
export const config = {
    apiKey: 'AIzaSyDC065QP86xTWvVvHswCokziUMuC2BZ3-w',
    authDomain: 'flashdict-a85d1.firebaseapp.com',
    databaseURL: 'https://flashdict-a85d1.firebaseio.com',
    projectId: 'flashdict-a85d1',
    storageBucket: 'flashdict-a85d1.appspot.com',
    messagingSenderId: '797151095732'
};

@Injectable()
export class DeckService {
    constructor() {
    }

    getDeckById(deckId: string): Observable<Deck> {

        const ref = firebase.firestore().collection('decks').doc(deckId);

        return new Observable((observer) => {
            ref.get().then(function (doc) {
                if (doc.exists) {
                    const deck = new Deck();
                    Object.assign(deck, doc.data());
                    deck.id = deckId;
                    observer.next(deck);
                } else {
                    console.log('There is no deck with id ' + deckId);
                }
            }).catch(function (error) {
                console.log('Error getting deck:', error);
            });
        });
    }

    getDecksByUserId(userId: string): Observable<Deck[]> {
        const ref = firebase.firestore().collection('decks')
            .where('uid', '==', userId)
            .orderBy('creationDate', 'desc');

        return new Observable((observer) => {
            ref.onSnapshot((querySnapshot) => {
                const decks: Deck[] = [];
                querySnapshot.forEach(function (doc) {
                    const deck = new Deck();
                    Object.assign(deck, doc.data());
                    deck.id = doc.id;
                    decks.push(deck);
                });
                observer.next(decks);
            });
        });
    }

    getPublicDecks(): Observable<Deck[]> {
        const ref = firebase.firestore().collection('decks')
            .where('uid', '==', 'public')
            .orderBy('creationDate', 'desc');

        return new Observable((observer) => {
            ref.onSnapshot((querySnapshot) => {
                const decks: Deck[] = [];
                querySnapshot.forEach(function (doc) {
                    const deck = new Deck();
                    Object.assign(deck, doc.data());
                    deck.id = doc.id;
                    decks.push(deck);
                });
                observer.next(decks);
            });
        });
    }

    addDeck(deck: Deck): Observable<Deck> {
        deck.creationDate = new Date();

        const ref = firebase.firestore().collection('decks');

        return new Observable((observer) => {
            ref.add(Object.assign({}, deck)).then((doc) => {
                deck.id = doc.id;
                observer.next(deck);
            });
        });
    }

    deleteDeck(deck: Deck): Observable<Deck> {
        const ref = firebase.firestore().collection('decks');

        return new Observable((observer) => {
            ref.doc(deck.id).delete().then(() => {
                observer.next(deck);
            });
        });
    }
}


