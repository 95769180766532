import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/src/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  hide = true;

  constructor(private authService: AuthService, public dialog: MatDialog) {
  }

  login(userEmail: string, userPassword: string): void {
    this.authService.loginUserByEmail(userEmail, userPassword);
  }

  openPasswordResetDialog(): void {
    const dialogRef = this.dialog.open(PasswordResetDialogComponent);
  }
}

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: 'password-reset-dialog.html',
})
export class PasswordResetDialogComponent {

  constructor(public dialogRef: MatDialogRef<PasswordResetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public emailAddress: string,
    private authService: AuthService, ) {
  }

  onOkClick(): void {
    this.authService.sendPasswordResetEmail(this.emailAddress);
    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
