import { NgModule, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '../src/services/translate.service';
import { TranslatePipe } from '../src/pipe/translate.pipe';
import { HttpClientModule } from '@angular/common/http';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('de');
}

@NgModule({
  declarations: [
    TranslatePipe
  ],
  imports: [
    HttpClientModule,
  ],
  exports: [
    TranslatePipe
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
  ],
})
export class TranslationModule { }
