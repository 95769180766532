import { UserCredential } from '@firebase/auth-types';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { DeckService } from './deck.service';
import { SettingsService } from './settings.service';
import { UserSettings } from '../model/userSettings';
import { Language } from '../enum/language';

@Injectable()
export class AuthService {
    private userDetails: firebase.User = null;

    constructor(private router: Router,
        private deckService: DeckService,
        private afAuth: AngularFireAuth,
        private settingsService: SettingsService) {
        this.afAuth.auth.onAuthStateChanged(user => {
            if (user) {
                this.userDetails = user;
                localStorage.setItem('userid', user.uid);
            } else {
                this.userDetails = null;
            }
        });
    }

    onAuthStateChanged(): Observable<boolean> {
        return new Observable((observer) => {
            this.afAuth.auth.onAuthStateChanged(user => {
                if (user) {
                    observer.next(true);
                } else {
                    observer.next(false);
                }
            });
        });
    }

    public isLoggedIn(): boolean {
        if (localStorage.getItem('userid')) {
            return true;
        }
        return false;
    }

    public logout(): void {
        this.afAuth.auth.signOut()
            .then((res) => {
                localStorage.clear();
                this.router.navigate(['/']);
            });
    }

    public sendPasswordResetEmail(emailAddress: string) {
        this.afAuth.auth.sendPasswordResetEmail(emailAddress).then(function () {
        }).catch(function (error) {
        });
    }

    public getUserEmail(): string {
        if (this.userDetails) {
            return this.userDetails.email;
        }
        return '';
    }

    public getUserId(): string {
        return localStorage.getItem('userid');
    }

    public loginUserByEmail(userEmail: string, userPassword: string): void {
        this.signInRegular(userEmail, userPassword)
            .then((res) => {
                this.router.navigate(['/profile']);
            })
            .catch((err) => alert(err)
            );
    }

    public registerByUserEmail(userEmail: string, userPassword: string): void {
        this.signUpRegular(userEmail, userPassword)
            .then((res) => {
                this.afAuth.auth.onAuthStateChanged((user) => {
                    user.sendEmailVerification();
                    const settings = new UserSettings();
                    settings.lang = Language.German;
                    settings.uid = user.uid;
                    this.settingsService.addSettings(settings).subscribe();
                });
            })
            .catch((err) => alert(err)
            );

    }

    private signInRegular(email, password): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    private signUpRegular(email, password): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    }
}

