import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take, mergeMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Deck } from 'src/app/src/model/deck';
import { Card } from 'src/app/src/model/card';
import { CardService } from 'src/app/src/services/card.service';
import { DeckService } from 'src/app/src/services/deck.service';
import { NbToastrService } from '@nebular/theme';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-cardlist',
  templateUrl: './cardlist.component.html',
  styleUrls: ['./cardlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CardlistComponent implements OnInit {
  deck: Deck;
  cards: Card[] = [];
  deletedCards: Card[] = [];
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cards, event.previousIndex, event.currentIndex);
  }

  constructor(private cardService: CardService,
    private deckService: DeckService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private location: Location,
    private toastrService: NbToastrService) {
  }

  ngOnInit() {
    const deckId = this.route.snapshot.paramMap.get('id');
    this.deckService.getDeckById(deckId).subscribe(deck => this.deck = deck);
    this.cardService.getCardsForDeckId(deckId)
      .subscribe(card => {
        this.cards.push(card);
      });
  }

  onDelete(cardParam: Card): void {
    this.deletedCards.push(cardParam);
    this.cards = this.cards.filter(card => card !== cardParam);
  }

  onAdd(): void {
    const card = new Card();
    card.deckId = this.deck.id;
    this.cards.unshift(card);
  }

  onSave() {
    this.updateCards();
    this.deleteCards();
    this.toastrService.show(
      'Success',
      'Changes saved!');
  }

  private updateCards() {
    let index = 0;
    for (const card of this.cards) {
      card.index = index;
      if (card.creationDate === null || card.creationDate === undefined) {
        this.cardService.addCard(card)
          .pipe(mergeMap(persistedCard => this.cardService.updateCard(persistedCard)))
          .subscribe();
      } else {
        this.cardService.updateCard(card)
          .subscribe();
      }
      index = index + 1;
    }
  }

  private deleteCards(): void {
    for (const card of this.deletedCards) {
      this.cardService.deleteCard(card).subscribe();
    }
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  goBack(): void {
    this.location.back();
  }
}
