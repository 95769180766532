import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { EChartOption } from 'echarts';

@Component({
  selector: 'app-training-results',
  templateUrl: './training-results.component.html',
  styleUrls: ['./training-results.component.scss']
})
export class TrainingResultsComponent implements OnInit {


  @Input() inputNumberOfCorrectCards: number;
  @Input() inputNumberOfIncorrectCards: number;
  @Input() inputNumberOfAllCards: number;
  data: EChartOption;
  numberOfCorrectCards: number;
  numberOfIncorrectCards: number;
  numberOfAllCards: number;
  themeSubscription: any;

  constructor(private theme: NbThemeService) {
  }

  ngOnInit(): void {
    this.numberOfCorrectCards = this.inputNumberOfCorrectCards;
    this.numberOfIncorrectCards = this.inputNumberOfIncorrectCards;
    this.numberOfAllCards = this.inputNumberOfAllCards;

    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      const colors = config.variables;
      const echarts: any = config.variables.echarts;

      this.data = {
        // backgroundColor: echarts.bg,
        color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['Right', 'Wrong', 'Not studied'],
        },
        series: [
          {
            name: 'Results',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            data: [
              { value: this.numberOfCorrectCards, name: 'Right' },
              { value: this.numberOfIncorrectCards, name: 'Wrong' },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
              },
            },
            label: {
              normal: {
                position: 'inside'
              },
            },
            labelLine: {
              normal: {
              },
            },
          },
        ],
      };
    });
  }
}
