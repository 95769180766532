import { MatListModule, MatDialogModule, MatInputModule, MatIconModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbButtonModule } from '@nebular/theme';
import { LoginComponent, PasswordResetDialogComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { TranslationModule } from '../translation/translation.module';

const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
];
@NgModule({
  declarations: [
    RegisterComponent,
    LoginComponent,
    LogoutComponent,
    PasswordResetDialogComponent
  ],
  imports: [
    RouterModule.forChild(
      authRoutes
    ),
    TranslationModule,
    CommonModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NbCardModule,
    NbButtonModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [
    PasswordResetDialogComponent
  ],
})
export class AuthModule {
  constructor() {
  }
}
