import { MatToolbarModule, MatStepperModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NbCardModule, NbActionsModule, NbSelectModule, NbToastrModule, NbStepperModule, NbIconModule, NbButtonModule } from '@nebular/theme';
import { DecksComponent, DeleteDeckDialogComponent } from './decks.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CardService } from '../src/services/card.service';
import { DeckService } from '../src/services/deck.service';
import { AuthService } from '../src/services/auth.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DeckCreationComponent } from './deck-creation/deck-creation.component';
import { CardlistComponent } from './card-list/cardlist.component';
import { TranslationModule } from '../translation/translation.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

const decksRoutes: Routes = [
  {
    path: 'decks',
    component: DecksComponent
  },
  {
    path: 'createdeck',
    component: DeckCreationComponent
  },
  {
    path: 'cards/:id',
    component: CardlistComponent
  }
];

@NgModule({
  declarations: [
    DecksComponent,
    DeckCreationComponent,
    DeleteDeckDialogComponent,
    CardlistComponent],
  imports: [
    RouterModule.forChild(
      decksRoutes
    ),
    TranslationModule,
    NbToastrModule.forRoot(),
    CommonModule,
    NbCardModule,
    NbActionsModule,
    NbSelectModule,
    NbStepperModule,
    MatToolbarModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    NbIconModule,
    DragDropModule,
    NbButtonModule
  ],
  providers: [
    AuthService,
    DeckService,
    CardService],
  entryComponents: [
    DeleteDeckDialogComponent]
})
export class DecksModule { }
