import { SettingsService } from './../src/services/settings.service';
import { Component, OnInit, ValueProvider } from '@angular/core';
import { AuthService } from '../src/services/auth.service';
import { TranslateService } from '../src/services/translate.service';
import { Language } from '../src/enum/language';
import { UserSettings } from '../src/model/userSettings';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  selectedLanguage: Language;
  languageKeys: String[];
  language = Language;

  constructor(private authService: AuthService,
    private translate: TranslateService,
    private settingsService: SettingsService) {
    this.languageKeys = Object.keys(this.language);
  }

  ngOnInit(): void {
    this.getUserSettings().subscribe(settings => { this.selectedLanguage = settings.lang; this.translate.use(this.selectedLanguage); });
  }

  getUserEmail(): string {
    return this.authService.getUserEmail();
  }

  sendPasswordResetEmail(emailAddress): void {
    this.authService.sendPasswordResetEmail(emailAddress);
  }

  onChangeLang(lang: Language): void {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    this.getUserSettings().subscribe(settings => {
      this.updateLanguage(settings);
    });
  }

  private getUserSettings(): Observable<UserSettings> {
    return this.settingsService.getUserSettingsByUserId(this.authService.getUserId());
  }

  private updateLanguage(settings: UserSettings) {
    settings.lang = this.selectedLanguage;
    this.settingsService.updateSettings(settings).subscribe();
  }

}
