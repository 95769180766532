import { Language } from '../../src/enum/language';
import { AuthService } from '../../src/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeckService } from 'src/app/src/services/deck.service';
import { Deck } from 'src/app/src/model/deck';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-deck-creation',
  templateUrl: './deck-creation.component.html',
  styleUrls: ['./deck-creation.component.scss']
})
export class DeckCreationComponent implements OnInit {

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  deckName: string;
  selected: Language = Language.English;
  language = Language;
  keys: String[];

  constructor(private _formBuilder: FormBuilder,
    private location: Location,
    private deckService: DeckService,
    private authService: AuthService,
    private toastrService: NbToastrService) {
    this.keys = Object.keys(this.language);
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
  }

  onOkClick(): void {

    const newDeck = this.createDeckFromInput();

    if (newDeck.frontLanguage === newDeck.backLanguage) {
      this.toastrService.show(
        'Warning',
        'Front and back card language must be different!');
      return;
    }

    this.deckService.addDeck(newDeck).subscribe();
    this.onGoBack();
  }

  private createDeckFromInput(): Deck {
    const newDeck = new Deck();

    newDeck.name = this.firstFormGroup.controls['firstCtrl'].value;
    newDeck.uid = this.authService.getUserId();
    newDeck.creationDate = new Date();
    newDeck.frontLanguage = this.secondFormGroup.controls['secondCtrl'].value;
    newDeck.backLanguage = this.thirdFormGroup.controls['thirdCtrl'].value;

    return newDeck;
  }

  onGoBack(): void {
    this.location.back();
  }
}
