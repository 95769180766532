
import { Component, OnInit, ViewChild } from '@angular/core';
import { DeckService } from './src/services/deck.service';
import { CardService } from './src/services/card.service';
import { NbMenuItem, NbIconLibraries } from '@nebular/theme';
import { AuthService } from './src/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material';
import { map } from 'rxjs/operators';
import { NavigationRoute } from './src/bc/NavigationRoute';
import { TranslateService } from './src/services/translate.service';
import { SettingsService } from './src/services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CardService, DeckService]
})

export class AppComponent implements OnInit {

  // TODO: public fields async and over are needed for productive deployment
  public async: any;
  public over: any;

  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;
  routes: NavigationRoute[] = [
    new NavigationRoute('signUp', '/auth/register', false, '', 'person-add'),
    new NavigationRoute('signIn', '/auth/login', false, '', 'log-in-outline'),
    new NavigationRoute('publicDecks', '/public/decks', true, '', 'home'),
    new NavigationRoute('publicDecks', '/public/decks', false, '', 'home'),
    new NavigationRoute('myDecks', '/decks', true, '', 'folder-outline'),
    new NavigationRoute('profile', '/profile', true, '', 'person'),
    new NavigationRoute('signOut', '/auth/logout', true, 'logout', 'log-out-outline')];

  items: NbMenuItem[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private translate: TranslateService,
    private settingsService: SettingsService,
    private iconLibraries: NbIconLibraries) {
    this.authService.onAuthStateChanged().subscribe(loggedIn => {
      this.createSideBarLinks();
    });
    this.settingsService.onSettingsChanged(authService.getUserId()).subscribe(settings => {
      translate.use(settings.lang).then(() => {
        this.createSideBarLinks();
      });
    });

    this.iconLibraries.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fal', { packClass: 'fal', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fad', { packClass: 'fad', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fab', { packClass: 'fab', iconClassPrefix: 'fa' });
  }

  createSideBarLinks() {
    // items is used for sidenav menu
    this.items = [];
    const rootItem = new NbMenuItem();
    rootItem.title = 'Menu';
    rootItem.expanded = true;
    rootItem.children = [];
    for (const route of this.routes) {
      if (route.isUserRequired === this.authService.isLoggedIn()) {
        const menuItem = new NbMenuItem();
        menuItem.title = this.translate.data[route.name] || route.name;
        menuItem.link = route.path;
        menuItem.icon = route.icon;
        if (this.router.isActive(menuItem.link, false)) {
          menuItem.selected = true;
        }
        rootItem.children.push(menuItem);
      }
    }
    this.items.push(rootItem);
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      if (this.isHandset$) {
        this.sidenav.close();
      }
    });
  }

  isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  isUserNotLoggedIn(): Boolean {
    return !this.isUserLoggedIn();
  }

  logout(): void {
    this.authService.logout();
  }

  // TODO: Performance issue: Don't use ngIf with functions
  isRouteVisible(route: NavigationRoute): Boolean {
    if (route.isUserRequired) {
      return this.isUserLoggedIn();
    }
    return this.isUserNotLoggedIn();
  }

  // TODO: Performance issue: Don't use ngIf with functions
  doOnClickAction(route: NavigationRoute): void {
    if (route.onClick === 'logout') {
      this.logout();
    }
  }
}



