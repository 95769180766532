import { Card } from '../model/card';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';

const settings = { timestampsInSnapshots: true };
export const config = {
    apiKey: 'AIzaSyDC065QP86xTWvVvHswCokziUMuC2BZ3-w',
    authDomain: 'flashdict-a85d1.firebaseapp.com',
    databaseURL: 'https://flashdict-a85d1.firebaseio.com',
    projectId: 'flashdict-a85d1',
    storageBucket: 'flashdict-a85d1.appspot.com',
    messagingSenderId: '797151095732'
};

@Injectable()
export class CardService {

    getCards(): Observable<Card[]> {
        const ref = firebase.firestore().collection('cards')
            .orderBy('index', 'asc')
            .orderBy('creationDate', 'desc');;

        return new Observable((observer) => {
            ref.onSnapshot((querySnapshot) => {
                const cards: Card[] = [];
                querySnapshot.forEach((doc) => {
                    const card = new Card();
                    Object.assign(card, doc.data());
                    card.id = doc.id;
                    cards.push(card);
                });
                observer.next(cards);
            });
        });
    }

    getCardsForDeckId(deckId: string): Observable<Card> {

        const ref = firebase.firestore().collection('cards')
            .where('deckId', '==', deckId)
            .orderBy('index', 'asc')
            .orderBy('creationDate', 'desc');

        return new Observable((observer) => {
            ref.get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        const card = new Card();
                        Object.assign(card, doc.data());
                        card.id = doc.id;
                        observer.next(card);
                    });
                })
                .catch(function (error) {
                    console.log('Error getting documents: ', error);
                });
        });
    }

    addCard(newCard: Card): Observable<Card> {
        const ref = firebase.firestore().collection('cards');
        newCard.creationDate = new Date();
        return new Observable((observer) => {
            ref.add(Object.assign({}, newCard)).then((doc) => {
                newCard.id = doc.id;
                observer.next(newCard);
            });
        });
    }

    updateCard(card: Card): Observable<Boolean> {
        const ref = firebase.firestore().collection('cards');
        return new Observable((observer) => {
            ref.doc(card.id).set(Object.assign({}, card)).then(() => {
                observer.next(true);
            });
        });
    }

    deleteCard(card: Card): Observable<Card> {
        const ref = firebase.firestore().collection('cards');
        return new Observable((observer) => {
            ref.doc(card.id).delete().then(() => {
                observer.next(card);
            });
        });
    }
}


