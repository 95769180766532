import { CardService } from './src/services/card.service';
import { DeckService } from './src/services/deck.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthService } from './src/services/auth.service';
import { MatListModule } from '@angular/material';
import { ProfileComponent } from './profile/profile.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';
import { MatMenuModule, MatToolbarModule, } from '@angular/material';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, MatSidenavModule } from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { NbThemeModule, NbLayoutModule, NbSelectModule, NbIconModule, NbButtonModule } from '@nebular/theme';
import { NbSidebarModule } from '@nebular/theme';
import { NbActionsModule, NbMenuModule } from '@nebular/theme';
import { MemorizeModule } from './memorize/memorize.module';
import { DecksModule } from './decks/decks.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule } from './auth/auth.module';
import { HomeComponent } from './home/home.component';
import { TranslationModule } from './translation/translation.module';
import { HttpClientModule } from '@angular/common/http';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { SettingsService } from './src/services/settings.service';
import { MarkdownModule } from 'ngx-markdown';

const menuRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'decks',
    loadChildren: () => import('./decks/decks.module').then(m => m.DecksModule)
  },
  {
    path: 'memorize',
    loadChildren: () => import('./memorize/memorize.module').then(m => m.MemorizeModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./public-decks/public-decks.module').then(m => m.PublicDecksModule)
  }
];
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ProfileComponent,
    HomeComponent,
  ],
  imports: [
    NbThemeModule.forRoot({ name: 'dark' }),
    NbMenuModule.forRoot(),
    RouterModule.forRoot(
      menuRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    MarkdownModule.forRoot(),
    MemorizeModule,
    DecksModule,
    AuthModule,
    TranslationModule,
    BrowserModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatToolbarModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    NbLayoutModule,
    NbActionsModule,
    NbSidebarModule,
    NbSelectModule,
    NbEvaIconsModule,
    NbIconModule
  ],
  exports: [
    AppComponent,
    PageNotFoundComponent,
    AngularFireAuthModule
  ],
  providers: [
    AuthService,
    DeckService,
    CardService,
    SettingsService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 1000 } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(afs: AngularFirestore) {
  }
}
