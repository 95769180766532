import { NgxEchartsModule } from 'ngx-echarts';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbProgressBarModule, NbCardModule, NbActionsModule, NbButtonModule } from '@nebular/theme';
import { MemorizeComponent } from './memorize.component';
import { Routes, RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material';
import { TrainingResultsComponent } from './training-results/training-results.component';
import { TranslationModule } from '../translation/translation.module';

const memorizeRoutes: Routes = [
  {
    path: 'memorize/:id',
    component: MemorizeComponent
  }
];

@NgModule({
  declarations: [
    MemorizeComponent,
    TrainingResultsComponent],
  imports: [
    RouterModule.forChild(
      memorizeRoutes
    ),
    CommonModule,
    NgxEchartsModule,
    NbProgressBarModule,
    MatToolbarModule,
    NbCardModule,
    NbActionsModule,
    TranslationModule,
    NbButtonModule
  ],
  exports: [
    MemorizeComponent]
})
export class MemorizeModule {
}
